<template>
	<v-container fluid fill-height>
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <base-heading :title="title">
          <base-btn v-if="categories.length>0" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
    </v-row>
    <v-row>
			<v-col cols="12">
				<base-data-table 
          v-model="sortBy"
					:headers="headers"
					:items="categories" 
          :showDialog="dialog"
          showEnabledField
					@loadDataTable="toList"
          @newItem="open"
        >
          <template v-slot:item.options="{ item }">
            <base-icon-btn @click="editItem(item)" icon="mdi-pencil" tooltip="Editar"></base-icon-btn>

            <template v-if="item.enabled">
              <base-icon-btn color="red darken-1" @click="enableDisableItem(item, 2)" icon="mdi-cancel" tooltip="Desactivar"></base-icon-btn>
            </template>
            <template v-else>
              <base-icon-btn color="green darken-1" @click="enableDisableItem(item, 1)" icon="mdi-check" tooltip="Activar"></base-icon-btn>
            </template>
          </template>

          <template v-slot:content>
            <base-form-simple ref="CategoryForm"
              :formTitle="formTitle"
              :formLoading="formLoading"
              @cancelButton="close"
              @saveButton="save"
            >
              <template v-slot:formContent>
                <v-container class="no-padding" fluid>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <base-text-field v-model="editedItem.visualName" label="Nombre" counter maxlength="50" 
                      :rules="[commonValRules.required, validationRules.visualNameRules.counter]" required></base-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <base-textarea outlined v-model="editedItem.description" label="Descripción" counter maxlength="250" 
                      :rules="[validationRules.descriptionRules.counter]"></base-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </base-form-simple>
          </template>
				</base-data-table>
			</v-col>
		</v-row>

    <base-pdf-viewer :show="showPdfViewer" :title="title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
      title: 'Categorías',
			sortBy: 'visualName',
			categories: [],
			headers: [
        { text: "Opciones", value: "options", sortable: false },
        { text: "Nombre", value: "visualName" },
        { text: "Descripción", value: "description", sortable: false},
        { text: "Estado", value: "enabled"}
			],
			editedItem: {
        id: '',
        visualName: '',
        description: '',
      },
      defaultItem: {
        id: '',
        visualName: '',
        description: '',
			},
			editData: false,
      dialog: false,
      formLoading: false,
      validationRules: {
        visualNameRules: {
          counter: value => (value.length >= 3 && value.length <= 50) || 'No debe tener más de 50 caracteres, ni menos de 3 caracteres.',
        },
        descriptionRules: {
          counter: value => (value.length <= 250) || 'No debe tener más de 250 caracteres.',
        },
      },

      //PDF report
			showPdfViewer: false,
			reportFile:{
				fileContent:'',
				fileName:''
			},
			defaultReportFile:{
				fileContent:'',
				fileName:''
			},
		}
  },
  
  computed: {
		formTitle() {
      return this.editData ? "Actualizar datos" : "Nuevo registro";
    },
	},

	created() {
    this.toList();
  },

	methods: {
		async toList(){
      let me=this;
      try {
        me.SHOW_LOADING()
        me.categories=[]
        await me.getObjectResponse('api/ProductCategory/GetAll', me.baseRequest).then(data => {
          if (data !== undefined) {
            me.categories = data.categories; 
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

		async editItem(item) {
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getObjectResponse('api/ProductCategory/GetById', request).then(data => {
          if (data === undefined) {
            return;
          }
          me.editedItem = Object.assign({}, data.category);
          me.editData = true;
          me.dialog = true;
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    open(){
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.clean();
    },
    
    clean(){
      this.editData = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.$refs.CategoryForm.reset();
    },

    async save() {
      let me=this;
      try {
        me.SHOW_LOADING()
        me.formLoading=true;
        if (me.editData) {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'id': me.editedItem.id,
            'visualName': me.editedItem.visualName,
            'description': me.editedItem.description
          };
          await me.getObjectResponse('api/ProductCategory/Update', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.category);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        } 
        else {
          let request={
            'companyId': me.baseRequest.companyId,
            'userId': me.baseRequest.userId,
            'visualName': me.editedItem.visualName,
            'description': me.editedItem.description
          };
          await me.getObjectResponse('api/ProductCategory/Create', request).then(data => {
            if (data == undefined) {
              return;
            }
            me.addOrUpdateListItem(data.category);
            me.close();
          }).catch(function(error){
            me.$swal(me.swalConfig.errorTitle, error.message, 'error');
          });
        }
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
    },

    addOrUpdateListItem(item){
			let me=this;
			if (!!me.categories.find((x) => x.id === item.id)) {
				const index = me.categories.findIndex((x) => x.id === item.id)
				me.categories.splice(index, 1, item)
			} else {
				me.categories.push(item);
			}
		},

    async enableDisableItem(item, action){
			let me = this;
			if (!await me.showEnableDisableConfirmDialog(action, `la categoría <strong>${item.visualName}</strong>`)) {
				return;
			}

			if (action==1) {
				me.enable(item);
			}
			else if (action==2) {
				me.disable(item);
			}
		},

    async enable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/ProductCategory/Enable', request).then(data => {
          if (data) {
            item.enabled = true;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async disable(item){
      let me=this;
      try {
        me.SHOW_LOADING()
        let request={
          'companyId': me.baseRequest.companyId,
          'userId': me.baseRequest.userId,
          'id': item.id,
        };
        await me.getBaseResponse('api/ProductCategory/Disable', request).then(data => {
          if (data) {
            item.enabled = false;
            me.showSuccessModal();
          }
        }).catch(function(error){
          me.$swal(me.swalConfig.errorTitle, error.message, 'error');
        });
      } catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
				};
				await me.getObjectResponse('api/ProductCategory/GetMainReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
		},
	},
}
</script>